import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "downloads/DSC_0163.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <section className="section-lg bg-primary text-light p-0 o-hidden">
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#040e18`}
          >
            <div className="overlay">
              <div
                className="row py-6 layer-2 min-vh-50 justify-content-center align-items-center text-center aos-init aos-animate"
                data-aos="fade-up"
              >
                <div className="col-lg-10 col-xl-8 col-auto">
                  <h1
                    className="display-3 text-white aos-init"
                    data-aos="zoom-in-up"
                  >
                    Visit Rwanda! Gain personal growth and create positive
                    impact to your society!
                  </h1>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </section>
      )
    }}
  />
)

const Background = styled(BackgroundSection)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
  position: relative;
`

export default Background
