import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/headerexchange"

const Partnership = () => (
  <Layout>
    <SEO title="Exchange Programs" />

    <Header />
    <section className="section bg-dark text-light">
      <div className="container-fluid text-center py-4">
        <div className="row py-4 justify-content-center">
          <div className="col-5">
            <h3 className="text-uppercase">EXPERIENCE RWANDA </h3>
          </div>
        </div>
      </div>
    </section>

    <section className="section py-8  px-8 pb-9">
      <div className="container-fluid">
        <div className="py-8">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-7 order-lg-2">
              <p className=" my-4">
                Study in Rwanda program is designed to assist our partner
                institutions and their coordinators in advising future incoming
                exchange students to Rwanda. Study in Rwanda facilitates formal,
                mutual agreements with international institutions. Our bilateral
                student exchange partners will either send or receive students.
              </p>
              <p className="my-4">
                Exchange programs facilitated by Study In Rwanda are achieved in
                partnership with the Government Ministries, Institutions,
                partner brands and individuals for a seamless exchange
                experience for the participants; pre, post and during their
                exchange program.
              </p>
            </div>
            <div className="col-lg-4 order-lg-1">
              <div className="card mb-0 mr-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/downloads/DSC_0061.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-8">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h5 className="h3 font-weight-bold text-center">
                WELCOME TO RWANDA!
              </h5>
              <p>
                This experience helps broaden the knowledge and skills of the
                exchange participants as the program comes with diverse
                activities to have the participants well-grounded about Rwanda,
                its Government, Institutions, history, while boosting their hard
                and soft skills with trainings, seminars and workshops that are
                add-ons to the exchange program.
              </p>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7">
              <h5 className="h3 font-weight-bold">
                WHAT DO STUDENTS GAIN FROM EXCHANGE PROGRAMS?
              </h5>
              <p className="my-4">
                <ul>
                  <li>They get to Visit Rwanda the pride of East Africa</li>
                  <li>
                    Students get a wider scope of research ideas and broaden
                    their general knowledge.
                  </li>
                  <li>The students get to network.</li>
                  <li>
                    Students are propelled towards understanding and learning
                    other community perspectives
                  </li>
                  <li>Students enhance their interest in global issues</li>
                  <li>
                    Students get to acquire new languages through practical
                    immersion
                  </li>
                  <li>Students get more comfortable in foreign environments</li>
                </ul>
              </p>
              <p className="my-4">
                For more information talk to us: <a href="mailto:info@studyinrwanda.rw">info@studyinrwanda.rw</a>{" "}
              </p>
            </div>
            <div className="col-lg-5">
              <div className="card mb-0 ml-lg-5">
                <div className="card-body p-2">
                <img
                    alt="Image placeholder"
                    src={require("../images/downloads/sir.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-7 order-lg-2">
              <h5 className="h3 font-weight-bold">
                Incoming Exchange Students
              </h5>
              <p className="my-4">
                Study in Rwanda will host exchange students from our
                international partner institutions for one semester or for a
                full year or less depending on the agreed period.
                <br />
                The program only allows students currently studying at our
                partner institutions to participate in exchange programs.
                <br />
                All students participating in exchange programs must have
                official permit letters from their institutions and meet
                requirements for participation at their institution.
              </p>
            </div>
            <div className="col-lg-5 order-first">
              <div className="card mb-0 ml-lg-5">
                <div className="card-body p-2">
                <img
                    alt="Image placeholder"
                    src={require("../images/downloads/RY6B1025.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-6">
              <h5 className="h3 font-weight-bold text-center">
                STUDENT ACCOMODATION
              </h5>
              <p className="h3 font-weight-bold text-center text-primary">HOUSING</p>
              <p className="my-4">
                Study in Rwanda policy might require all our exchange students
                to live on campus or in the accommodation space provided.
                <br />
                Our Exchange Team can always reserve space for exchange students
                according to the preferences given in exchange applications.
                <br />
                Study in Rwanda requires institutions to submit applications on
                time to guarantee reserved living space.
                <br />
                More information about accommodation can be discussed before
                departure.
              </p>

              <p className="h3 font-weight-bold text-center text-primary">STUDENT MEAL PLAN</p>
              <p className="my-4">
                Exchange students meals are organized according to the budget
                set in exchange applications.
              </p>

              <p className="h3 font-weight-bold text-center text-primary">HEALTH INSURANCE</p>
              <p className="my-4">
                Study in Rwanda separates the cost for insurance for students.
                However, institutions can make arrangements for students to pay
                mandatory insurance. Study in Rwanda can always negotiate
                affordable medical insurance in Rwanda.
              </p>
              <p className="my-4">
                For more information talk to us: <a href="mailto:info@studyinrwanda.rw">info@studyinrwanda.rw</a>{" "}
              </p>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="card mb-0 mr-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/downloads/kigali-1.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Partnership
